.columns {
  float: left;
  text-align: center;
}

.columns-1 {
  width: 100%
}

.columns-2 {
  width: 50%;
}

.columns-3 {
  width: 33%;
}

.columns-4 {
  width: 25%
}

.columns-5 {
  width: 20%;
}

.columns-6 {
  width: 16.666667%
}

.columns-7 {
  width: 14.285714%
}

.columns-8 {
  width: 12.5%;
}

.columns-9 {
  width: 11.111111%
}

.columns-10 {
  width: 10%;
}

.columns-11 {
  width: 9.090909%
}

.columns-12 {
  width: 8.333333;
}

/**
* Navigation CSS
*/
nav ul li ul {
  margin-left: 16px;
}

.featured h1{
    font-size: 4em;
    letter-spacing: -3px;
    margin: 0.25em 0 0.75em 0;
    line-height: 45px;
}
