html,
body,
p,
ul,
ol,
a,
.pure-g [class *= "pure-u"] {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
}

h1,
h2,
h3 {
  font-weight: 300;
  color: #373737;
  padding: 0;
  line-height: 100%;
}

p,
ul,
ol,
a {
  color: #000;
}

h1 {
  font-size: 3em;
  margin: 5px 0 20px;
}

h2 {
  margin: 5px 0 15px;
  font-size: 1.8em;
}

h3 {
  font-weight: 400;
  margin: 5px 0 10px;
  font-size: 1.2em;
}

h1,
h2,
h3,
p,
ul,
ol {
  float: none !important; /* overwrite .row>* :-( */
}

p {
  margin: 5px 0 10px;
}

a {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

.wh-video {
  margin: 5px 0 25px;
}

.wh-rtd-editor {
  padding: 3px;
}

.wh-rtd-editor h1.heading1 {
  text-transform: initial;
}
